<template>
  <AuthPageTemplate
    :button="$t('auth.reset.button.save')"
    :errorCard="errorCardStatus"
    :loadingCard="checkingResetCode"
    :loading="loading"
    :disabled="!isValid"
    :error="errorHandle"
    @click="resetPassword"
  >
    <!-- invalid OOB Code -->
    <template v-slot:error>
      <v-col justify="center" align="center" style="height: 100%">
        <div
          style="
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          "
        >
          <v-icon size="60" color="primary">mdi-close-circle-outline</v-icon>
          <div>
            <b class="primary--text" style="font-size: 17px">{{
              $t('auth.reset.invalidLink')
            }}</b>
          </div>
        </div>
        <TextButton
          class="back-button"
          v-on:click="$router.push({name: 'restore'}).catch(() => {})"
          dense
          icon="mdi-chevron-left"
          :text="$t('auth.reset.button.toRestore')"
        />
      </v-col>
    </template>
    <template v-slot:input>
      <div class="black--text mb-6" style="font-size: 15px">
        <div style="font-size: 20px" class="mb-1 black--text">
          <b>{{ $t('auth.reset.title') }}</b>
        </div>
        <span style="font-size: 15px" class="grey--text font-weight-normal">{{
          $t('auth.reset.text')
        }}</span>
      </div>

      <!-- PASSWORD INPUT FIRST TIME -->
      <div style="height: 115px">
        <TextInput
          :disabled="loading"
          class="mt-4"
          password
          v-on:change="updatePasswordEvent"
          :placeholder="$t('auth.reset.input.password1')"
          :value="password"
          v-on:focus="focusEvent"
        />
        <!-- SHOW STRENGTH OF PASSWORD -->
        <v-expand-transition>
          <PasswordStrength
            v-show="strengthFocus"
            :disabled="!strengthFocus || loading"
            class="ml-6"
            :password="password"
          />
        </v-expand-transition>
        <!-- PASSWORD INPUT SECOND TIME -->
        <TextInput
          :disabled="loading"
          class="mt-2 mb-4"
          password
          v-on:change="updatePasswordConfirmEvent"
          :placeholder="$t('auth.reset.input.password2')"
          :value="passwordConfirm"
        />
      </div>
    </template>

    <template v-slot:bottom>
      <TextButton
        dense
        icon="mdi-chevron-left"
        v-on:click="$router.push('login').catch(() => {})"
        class="mt-2"
        block
        :text="$t('auth.reset.button.toLogin')"
      />
    </template>
  </AuthPageTemplate>
</template>

<script>
import AuthPageTemplate from '@/components/auth/AuthPageTemplate.vue';
import TextInput from '@components/text/TextInput.vue';
import TextButton from '@components/button/TextButton.vue';
import PasswordStrength from '@/components/auth/PasswordStrength.vue';

export default {
  name: 'ResetView',
  components: {
    AuthPageTemplate,
    PasswordStrength,
    TextButton,
    TextInput,
  },

  data() {
    return {
      checkingResetCode: true,
      loading: false,
      errorHandle: {
        status: false,
        text: '',
      },

      errorCardStatus: false,

      password: '',
      strengthFocus: false,
      passwordConfirm: '',
      mail: '',
    };
  },

  async created() {
    var resp = await this.$auth.verifyCode(this.$route.query.oobCode);

    if (resp == null) {
      this.errorCardStatus = true;
    }

    this.checkingResetCode = false;
  },

  watch: {
    password() {
      if (this.password.length > 0) this.errorHandle.status = false;
    },
    passwordConfirm() {
      if (this.passwordConfirm.length > 0) this.errorHandle.status = false;
    },
  },
  computed: {
    isMail() {
      return this.$helper.isValidMail(this.mail);
    },
    isValid() {
      const verifyPassword = () => {
        return (
          this.password != null &&
          this.password.length >= 6 &&
          this.password == this.passwordConfirm
        );
      };
      if (verifyPassword()) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    focusEvent(eventHandle) {
      if (eventHandle.type == 'focus') this.strengthFocus = true;
      else this.strengthFocus = false;
    },
    updatePasswordEvent(passwordString) {
      this.password = passwordString;
    },

    updatePasswordConfirmEvent(passwordString) {
      this.passwordConfirm = passwordString;
    },
    updateMailEvent(mailString) {
      this.mail = mailString;
    },

    async resetPassword() {
      this.loading = true;
      this.errorHandle.status = false;
      this.strengthFocus = false;

      const resp = await this.$auth.resetPassword(
        this.password,
        this.$route.query.oobCode,
        this.$route.query.apiKey,
      );

      if (resp == null) {
        this.errorHandle.text = this.$t('auth.reset.error');
        this.errorHandle.status = true;

        this.password = '';
        this.passwordConfirm = '';
      } else {
        this.$router.push({
          name: 'login',
          params: {lang: this.$route.params.lang},
          query: {type: 'reset'},
        });
      }

      this.loading = false;
    },
  },
};
</script>

<style>
.back-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  border: 1px solid #c0c0c0;
}
</style>
