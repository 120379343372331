<template>
  <div class="" :style="disabled ? 'opacity: .4' : ''">
    <v-row class="ma-0 pt-1" align="center">
      <!-- SHORT LINES WITH STYLE -->
      <div
        v-for="(line, index) in shortLines"
        :key="'line' + index"
        class="mr-1 mt-0"
        style="width: 25px; height: 5px; border-radius: 3px"
        :style="{background: passwordStrengthStyle(line)}"
      />

      <div style="font-size: 13px; padding-top: 0px">
        <span class="font-weight-light" style="color: rgba(0, 0, 0, 0.5)">
          <!-- TEXT STATUS -->
          <span v-if="isInSecure">{{
            $t('auth.passwordStrength.insecure')
          }}</span>
          <span v-if="isWeak">{{ $t('auth.passwordStrength.weak') }}</span>
          <span v-if="isMedium">{{ $t('auth.passwordStrength.medium') }}</span>
          <span v-if="isStrong"> {{ $t('auth.passwordStrength.secure') }}</span>
          <span v-if="isUseless">{{
            $t('auth.passwordStrength.useless')
          }}</span>
          <span v-if="strength.length <= 6">{{
            $t('auth.passwordStrength.minChar')
          }}</span>
        </span>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PasswordStrength',
  props: {
    password: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      shortLines: [0, 1, 2, 3],
      defaultOptions: [
        {
          id: 0,
          value: 'Too weak',
          minDiversity: 0,
          minLength: 0,
        },
        {
          id: 1,
          value: 'Weak',
          minDiversity: 2,
          minLength: 8,
        },
        {
          id: 2,
          value: 'Medium',
          minDiversity: 4,
          minLength: 10,
        },
        {
          id: 3,
          value: 'Strong',
          minDiversity: 4,
          minLength: 12,
        },
      ],
      symbolsOnKeyboard: '!"#\$%&\'\(\)\*\+,-\./:;<=>\?@\[\\\\\\]\^_`\{|\}~',
    };
  },

  computed: {
    strength() {
      return this.passwordStrength(this.password);
    },
    isUseless() {
      // password: aaaasss
      return (
        this.strength.length > 6 &&
        this.strength.id === 0 &&
        this.strength.contains.length === 1
      );
    },
    isInSecure() {
      // password: aaaass1
      return (
        this.strength.length > 6 &&
        this.strength.id === 0 &&
        this.strength.contains.length > 1
      );
    },
    isWeak() {
      // password: aaaasss12A
      return (
        this.strength.length > 6 &&
        this.strength.id === 1 &&
        this.strength.contains.length > 1
      );
    },
    isMedium() {
      // password: aaaasss12A@
      return (
        this.strength.length > 6 &&
        this.strength.id === 2 &&
        this.strength.contains.length > 1
      );
    },
    isStrong() {
      // password: aaaasss12A@a
      return (
        this.strength.length > 6 &&
        this.strength.id === 3 &&
        this.strength.contains.length > 1
      );
    },
  },

  methods: {
    passwordStrength(
      password,
      options = this.defaultOptions,
      allowedSymbols = this.symbolsOnKeyboard,
    ) {
      let passwordCopy = password || '';

      options[0].minDiversity = 0;
      options[0].minLength = 0;

      const rules = [
        {
          regex: '[a-z]',
          message: 'lowercase',
        },
        {
          regex: '[A-Z]',
          message: 'uppercase',
        },
        {
          regex: '[0-9]',
          message: 'number',
        },
      ];

      if (allowedSymbols) {
        rules.push({
          regex: `[${allowedSymbols}]`,
          message: 'symbol',
        });
      }

      let strength = {};

      strength.contains = rules
        .filter((rule) => new RegExp(`${rule.regex}`).test(passwordCopy))
        .map((rule) => rule.message);

      strength.length = passwordCopy.length;

      let fulfilledOptions = options
        .filter((option) => strength.contains.length >= option.minDiversity)
        .filter((option) => strength.length >= option.minLength)
        .sort((o1, o2) => o2.id - o1.id)
        .map((option) => ({id: option.id, value: option.value}));

      Object.assign(strength, fulfilledOptions[0]);

      return strength;
    },

    passwordStrengthStyle(id) {
      const green = 'rgb(33, 166, 122)';
      const blue = 'rgb(0, 113, 254)';
      const orange = 'rgb(255, 164, 55)';
      const red = 'rgb(255, 0, 0)';

      if (this.strength.length <= 6) return 'grey';

      switch (this.strength.id) {
        case 3:
          if (id <= 3) return green;
          else return 'grey';
        case 2:
          if (id <= 2) return blue;
          else return 'grey';
        case 1:
          if (id <= 1) return orange;
          else return 'grey';
        case 0:
          if (id <= 0 && this.strength.contains.length === 1) {
            return 'grey';
          } else if (id <= 0 && this.strength.contains.length > 1) return red;
          else return 'gray';
        default:
          return 'gray';
      }
    },
  },
};

/*
eslint
  no-useless-escape: 0
*/
</script>
